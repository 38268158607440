@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@import url('https://fonts.googleapis.com/css2?family=BenchNine:wght@400;700&family=Raleway&display=swap');

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

//@include parallax;
//Define colors and fonts
$headline-font: 'BenchNine', sans-serif;
$body-font: 'Raleway', 'Roboto Condensed', 'Verdana', sans-serif;

//https://material.io/design/color/the-color-system.html#tools-for-picking-colors
//Dark Dark Blue
$color-brand-primary-900: #070f19;
$color-brand-primary-800 : #292f39;
$color-brand-primary-600 : #59606b;
$color-brand-primary-500 : #808793;
$color-brand-primary-400 : #a1a8b5;
$color-brand-primary-200 : #d9e0ee;
$color-brand-primary-100: #e6edfb;
$color-brand-primary-50 : #f0f7ff;

//Tan
$color-brand-comp-900: #191107;
$color-brand-comp-800: #39312a; 
$color-brand-comp-700: #574f47;
$color-brand-comp-600: #6b625a;
$color-brand-comp-500: #938981;
$color-brand-comp-400: #b4aaa1;
$color-brand-comp-200: #ebe1d8;
$color-brand-comp-100: #f7ede3;
$color-brand-comp-50: #fff6ec;


//Grayish blue - analogous row 1
$color-brand-secondary-900: #071819;
$color-brand-secondary-800: #29383a;
$color-brand-secondary-600: #596a6b;
$color-brand-secondary-500: #809293;
$color-brand-secondary-400: #a0b2b4;
$color-brand-secondary-300: #c4d7d9;
$color-brand-secondary-200: #d5e8ea;
$color-brand-secondary-100: #e0f3f5;
$color-brand-secondary-50: #e8fcfd;


//Purple - Analogous row 2
$color-brand-alt-900: #080719;
$color-brand-alt-800: #2a2a39;
$color-brand-alt-700: #474758;
$color-brand-alt-600: #5a5a6b;
$color-brand-alt-500: #818093;
$color-brand-alt-400: #a2a2b5;
$color-brand-alt-200: #dcdbf0;
$color-brand-alt-100: #eae9fe;
$color-brand-alt-50: #f5f4ff;

//Second row
$color-brand-triad-900: #19070f;
$color-brand-triad-800: #392a2f;
$color-brand-triad-600: #6b5a60;
$color-brand-triad-500: #928087;
$color-brand-triad-400: #b4a1a8;
$color-brand-triad-200: #eedae1;
$color-brand-traid-100: #fbe7ee;
$color-brand-triad-50: #fff1f8;

$color-brand-primary: $color-brand-primary-900;
$color-brand-comp: $color-brand-comp-400;
$color-background: $color-brand-primary-800; // so we can easily change it
$color-surface: $color-brand-primary-600;
$color-font: #f3f9ff; //rgba(0,0,0,0.73);
$color-links: $color-brand-alt-200;
$color-links-hover: $color-brand-alt-50;

$color-batman: #27507f;
$color-platform: $color-brand-alt-700;
$color-spring-green: #cae43c; //#acca30;


* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
	font-family: $body-font;
	font-size: 16px;
	color: $color-font;
	background-color: $color-background;
	padding: 0px;
  margin: 0px;
}

body {
	overflow-x: hidden;
  overflow-y: auto;
   /* perspective: 1px;
    -webkit-perspective: 1px;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;*/
    padding-top: 90px; // lower than the nav
}

h1, h2, h3, h4, h5, h6 {
	font-family: $headline-font;
	font-weight:  700;
	text-transform: uppercase;
	color: $color-spring-green; //$color-brand-secondary-400;
}

a {
		font-family: $headline-font;
		color: $color-links;
		font-weight:  700;
		font-size: 1.2em;
		//letter-spacing: 1px;
		//text-transform: uppercase;

		&.inline-link {
			color: $color-links-hover;
			font-family:  $body-font;
			font-size: 0.8em;
			text-decoration: underline;
		}
}

a:hover {
	text-decoration: underline;
	color: $color-links-hover;

	&.inline-link {
		color: $color-links-hover;
		text-decoration: none;
	}
}

a:focus {
	color: $color-brand-alt-400;
}



h3 {
	& a {
		padding: 0 !important;
		display: flex;
		color: $color-brand-comp-50;
		&:hover {
			color:  $color-brand-comp-50;
			text-decoration:  none;
		}
	}
}

.button {
	font-family: $body-font;
	font-weight:  500;
	color: $color-brand-primary;
	background-color: $color-links;
	position: relative;
	z-index: 100;
	transition: all 0.21s ease;
	padding: 10px 16px;
	&:hover {
		color: black;
		//font-size: 1em;
		//font-weight: 600;
		letter-spacing: 1px;
		padding: 10px 8px;
		background-color: $color-links-hover;
	}
	&:focus {
		color: black;
		background-color: $color-links-hover;
	}
}

/*.reveal {
	& .button {
		&:hover {
			letter-spacing: 0px;
		}
	}
}*/
.top-bar, .top-bar ul, .title-bar {
	//background-color: $color-brand-secondary-500;
	background:  linear-gradient(180deg, $color-brand-secondary-500 0%, $color-brand-secondary-50 100%);
	.menu-text {
		float: left;
		margin: 0 auto 0 10px;
	}

	.lower-menu {
		position: relative;
		top: 15px;
	}

	& .material-icons {
			position: relative;
			top: 4px;
	}

	h3 {
		color: $color-brand-primary;
	}

	li, a {
		font-family: $headline-font;
		color: $color-brand-alt-800;
		font-weight:  700;
		text-transform: uppercase;
		font-size: 21px;	
		padding: 0 0 5px 0;
		line-height: 1;
		opacity: 0.8;

	}


	li {
		padding: 0.7rem 1rem;
		margin-bottom: 10px;
		color: $color-brand-triad-800;
		//text-decoration: line-through;
	}

	a {
			opacity: 1;
			padding: 0;
			text-decoration: none;
		}

	a:hover {
		text-decoration: underline;
		color: $color-brand-alt-700;
	};

	.title-bar-title {
		position: relative;
		top:  3px;
		left: 4px;
		color:  $color-brand-alt-800;
	}

	.menu-icon {
		height: 26px;
		&:after {
			display: none;
		}

	}

}
.top-bar {
	padding: 21px 0.25rem 0 0.25rem;
	//border-bottom: 10px solid $color-brand-secondary-800;
	margin-bottom: 10px;
	position: fixed; 
  top: 0;
  width: 100%;
  transition: top 0.3s;
  z-index: 999;
}

.dropdown {
	&.menu {
		 > li > a {
    	padding: 0;
	}

	}
}
.about {
	border-bottom:  8px solid $color-brand-secondary-600;
}
.pullquote {
	position: relative;
	//float: left;
	margin: 0 21px 8px;
	font-family: $headline-font;
	font-size: 1.6em;
	line-height: 1.1em;
	&.inline {
		margin-left:  2px;
		margin-right: 2px;
		top: 1px;
	}
}

img.center {
	display: block;
  margin-left: auto;
  margin-right: auto;
}

.flipit {
	position: relative;
	display: inline-block;
	overflow: hidden;

	.img-top {
		//display: none;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 99;
		transition: opacity .5s ease-in-out;

		&.full-width {
			width: 100%;
			height: auto;
		}
		&.full-height {
			width: auto;
			height: 100%;
		}
	}

	&:hover {
		.img-top {
			//display: inline;
			opacity: 1;
			transition: opacity .5s ease-in-out;
		}
	}
}

.half-width {
	width: 50%;
	height: auto;
}
.full-width {
	width: 100%;
	height: auto;
}
.fixed {
	position: fixed;
	z-index: 0;
}
.opacity60 {
	opacity: 0.6;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}


.no-overflow {
	overflow: hidden;
}

.logo-squirrel {
	height: 24px;
	transform: scaleX(-1);
	margin: -2px 6px 0;
}

.home-offset {
	position: relative;
	background-color: $color-background;
	top: 40vw;
	padding-top: 21px;
}

/****************** Case Study Styles ****************/
.case-study {
	//border: 1px solid black;
	margin: 42px;
	box-shadow: 6px 6px 10px $color-brand-primary;
	background-color:  $color-brand-primary-900; //$color-surface;
	border-bottom-right-radius: 10px;

	h4 {
		color: $color-brand-secondary-200;
	}
}

.project-details {
	font-size: 1.1em;
	color: $color-brand-secondary-400;
	background-color: $color-background;
	font-family: $headline-font;
	text-transform: uppercase;
	letter-spacing: 2px;
	position: relative;
	right: -30px;
	top: -5px;
	padding-top: 5px;
	padding-right: 20px;
	margin: 0 0 10px -30px;
	border-bottom-left-radius: 10px;
	text-align: right;


}
.humor {
	font-size: 0.8em;
	color: $color-brand-alt-200;
}
.tldr {
	position: relative;
	background-color: $color-surface;
	padding: 15px 10px 10px 10px;

	&:before {
		position: absolute;
		top: -5px;
		left: -1px;
		font-size: 16px;
		font-weight: 800;
		content: '#TLDR';
		color: $color-brand-primary-400;
	}
}
.superscript {
	position: relative;
	font-weight: 600;
	font-family: monospace;
	top: -10px;
	left: -2px;
}

.disclaimer {
  	position: absolute;
  	bottom: 0px;
  	right: 75px;
  	opacity: 0.8;
		&:before {
				position: absolute;
				top: -8px;
				left: -12px;
				font-size: 2em;
				font-weight: 800;
				content: '*';
				color: inherit;
			}
  }
/************************************     About       **********************/
.about-images {
	position: relative;

	& img {
		border-bottom-left-radius: 10px;
	}
}

.flip-x {
	transform:  scaleX(-1);
}

.west-olive {
		position: absolute;
		z-index: 10;
		top: -6px;
    width: 42%;
    right: 12px;
    transform: rotate(-23deg);
}
.skill-grid {
	h4 {
		color:  $color-brand-secondary-400;
	}
}
.skill-label {
	@include label();
	font-weight: 800;
	font-size: 0.9em;
	color: black;
	background-color: $color-spring-green;
	border-radius: 1000px;
	margin: 4px;
	padding: 10px 16px;
}

.work-experience {
	text-align: right;
	h5 {
		//color: $color-brand-secondary-200;
		margin-bottom: 0;
		letter-spacing:  1px;
	}
}
/*************************** Parallax  *************/
section {
  //width: 100vw;
  //height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //transform: translateZ(-7px) scale(1);
  z-index:30;
}
section.background {

	background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  background-position: center;


	&.batman {
		background-image: url('/assets/img/animation/bat_turns_sm.jpg');
		//background: url('/assets/img/animation/bat_turns.jpg');
		background-color: white;
		color: black;

		h2 {
			color: black;
		}
	}

  &.platform {
  	background-image: url('/assets/img/platform/meta-partial_sm.jpg');
  	//background: url('/assets/img/platform/meta-partial.jpg');
  	background-color: white;
  	color: black;

  	h2 {
  		color: black;
  	}
	}
	&.puremichigan {
		background-image:  url('/assets/img/animation/PM_background_sm.jpg');
		//background:  url('/assets/img/animation/PM_background.jpg');
		background-color: white;
		background-size: 70%;
		color: #e60f75;

		& h2 {
  		color:  #e60f75;
  	}

	}

	&.wedding {
		background-image:  url('/assets/img/wedding/wedding_bkg_sm.jpg');
		//background:  url('/assets/img/wedding/wedding_bkg_sm.jpg');
		background-color: #0f0e0c;
		background-size: 100%;
		color: #e60f75;

		& h2 {
  		color:  black;
  	}
  }
  
  
  position: relative;
  height: 25vh;
  width: 102vw;
  margin-left: -2rem;

  h2 {
  	font-weight: 900;
  	//color: $color-brand-primary;
	 	// -webkit-text-fill-color: $color-brand-primary; /* Will override color (regardless of order) */
	 	//color: ; -webkit-text-stroke-width: 2px;
	 	// -webkit-text-stroke-color: white;
  	text-shadow:
   		-1px -1px 0 #FFF,  
    	1px -1px 0 #FFF,
    	-1px 1px 0 #FFF,
     	1px 1px 0 #FFF;
  	
  }
}

.overlay {
	&.batman {
		/*TODO jquery up this animation dude*/
		display: none;
		background-color: black;
		witdh: 100vw;
		height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 10000;
	}
}

.reveal {
	img {
		display: block;
  	margin-left: auto;
  	margin-right: auto;
	}
}
.bg-alt {
  background: $color-brand-alt-600;
}
.bg-white {
	background:  white;
	color: $color-brand-primary;

	h1, h2, h3, h4, h5, h6 {
		color:  $color-brand-secondary-800;
	}
}
.bg-batman {
	color: white;;
	background-color: $color-batman;
}
.bg-platform {
	background-color: $color-platform;
}

/************************* FOOOOOTEERRRRR *********************************/
.footer {
	position: relative;
	min-height: 42px;
	background-color: black;
	margin-top: 21px;
	padding: 21px;
	bottom: 0;
	z-index: 21;
	ul {
		list-style: none;
	}

	& .material-icons {
		font-size: 2em;
	}
	.align-to-icon {
		position: relative;
		top: -12px;
		padding-left: 8px;
	}
}
.footer-menu {
	
		li, a {
		font-family: $headline-font;
		//color: $color-brand-alt-800;
		font-weight:  700;
		//text-transform: uppercase;
		font-size: 21px;	
		padding: 0 0 5px 0;
		line-height: 1;
		&.menu-text-casestudies {
			font-family: $body-font;
			font-size: 14px;
			letter-spacing: 2px;
			text-transform: uppercase;
			}
	}

	a {
		opacity: 1;
	}
}



//color filters for SVGs and stuff https://codepen.io/sosuke/pen/Pjoqqp
.filter-purple {
	filter: invert(19%) sepia(16%) saturate(5711%) hue-rotate(257deg) brightness(95%) contrast(111%);
}
.filter-brand-comp-400 { //Tan
	filter: invert(40%) sepia(75%) saturate(352%) hue-rotate(355deg) brightness(93%) contrast(81%);
}
.filter-brand-primary { //dark gray
	filter: invert(11%) sepia(9%) saturate(1927%) hue-rotate(179deg) brightness(96%) contrast(81%);
}
.filter-green {
	filter: invert(96%) sepia(78%) saturate(969%) hue-rotate(10deg) brightness(94%) contrast(89%);
}
.filter-white {
	filter: invert(97%) sepia(0%) saturate(1%) hue-rotate(350deg) brightness(113%) contrast(101%);
}

.off-canvas-wide {
	width: 500px;
}

.thumbnail-shrink {
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	cursor: pointer;

	&:hover {
		-webkit-transform: scale(0.96);
		-moz-transform: scale(0.96);
		-o-transform: scale(0.96);
		-ms-transform: scale(0.96);
		transform: scale(0.96);
	}

}



.reveal {
	h1, h2, h3, h4, h5, h6 {
		color:  $color-brand-secondary-900;
	}
	p {
		color: $color-brand-primary;
	}

	a {
		font-size: 1em;
		color:  $color-brand-alt-900;
		&:hover {
			color:  $color-brand-alt-600;
		}
	}
}

.text-dark {
	color: $color-brand-primary;
}

.grid-logos, .grid-illustration {
	background-color: white;
	border-top: 12px solid $color-brand-primary;
}

.grid-illustration {
	img {
		//max-width: 200px;
		//height: auto;
		max-height: 200px;
		width: auto;
		display: block;
  	margin-left: auto;
  	margin-right: auto;
		//clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
	}
}

/* some margin and padding classes */
.margin-top-10, .mt10 {
	margin-top: 10px;
}

.margin-top-21, .mt21 {
	margin-top: 21px;
}
.margin-top-42, .mt42 {
	margin-top:  42px;
}
.margin-bottom-10, .mb10 {
	margin-bottom: 10px;
}
.margin-bottom-21, .mb21 {
	margin-bottom:  21px;
}

.padding-top-10, .pt10 {
	padding-top: 10px;
}
.padding-bottom-10, .pb10 {
	padding-bottom: 10px;
}

.padding-top-21, .pt21 {
	padding-top: 21px;
}
.padding-bottom-21, .pb21 {
	padding-bottom: 21px;
}
.margin-right-21, .mr21 {
	margin-right: 21px;
}
.margin-left-21, .ml21 {
	margin-left: 21px;
}

.margin-both-sides {
	margin: 0 42px;
}
.padding-both-sides {
	padding: 0 42px;
}

.responsive-embed--text {
	margin-top:  60px;
}



/* Responsive: small breakpoint */
@media screen and (max-width: 800px) {
	.home-offset {
		//top: 340px;
	}
	.hide-for-mobile {
		display: none;
	}
}


@media screen and (max-width: 630px) {
	.responsive-embed--text {
		margin-top:  0px;
	}

	.margin-both-sides {
		margin: 0 12px;
		}
	.padding-both-sides {
		padding: 0 12px;
		}

	.hide-for-mobile {
		display: none;
	}
	section.background {
		margin-left: -1rem;
	}

	.top-bar ul {
		.menu-text {
			margin: 0;
		}
		.lower-menu {
			top: 0;
		}
	}

}